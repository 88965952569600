import { SimpleSelect, stringOption } from "../FormInput";
import ExportButton from "./ExportButton";
import useCaseListContext from "./useCaseListContext";
import { useCasesMutation } from "./useCasesQuery";

type Props = {
  cases: Cases.Case[];
};

type BulkUpdateOptions = {
  case_processor_id?: number;
  status?: string;
};

export default function Actions({ cases }: Props) {
  const { state, dispatch, options } = useCaseListContext();
  const { selected } = state;

  const selectUnchecked = selected.length === 0;

  const mutation = useCasesMutation();

  const bulkUpdate = (attrs: BulkUpdateOptions) => {
    mutation.mutate({
      url: "/saksbehandler/saker/bulk_update.json",
      data: {
        ids: selected,
        case_note: attrs
      }
    });
  };

  const handleChangeCaseProcessor = (id?: string) => {
    if (id) {
      bulkUpdate({ case_processor_id: parseInt(id) });
    }
  };

  const handleChangeStatus = (status: string) => {
    bulkUpdate({ status: status });
  };

  const handleLockDecisions = () => {
    mutation.mutate({
      url: "/saksbehandler/saker/lock_decisions.json",
      data: { ids: selected }
    });
  };

  const handleLockRecommendations = () => {
    mutation.mutate({
      url: "/saksbehandler/saker/lock_recommendations.json",
      data: { ids: selected }
    });
  };

  const handleChangeArchived = (archivedStatus: Cases.ArchiveValue) => {
    mutation.mutate({
      url: "/saksbehandler/saker/bulk_archive.json",
      data: { ids: selected, case: { archived: archivedStatus === "archived" } }
    });
  };

  const toggleSelected = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (selected.length === 0) {
      dispatch({ type: "setSelected", payload: cases.map((c) => c.id) });
    } else {
      dispatch({ type: "clearSelected" });
    }
  };

  const selectToggleIcon = () => {
    if (cases.filter((c) => !selected.includes(c.id)).length == 0) {
      return "check_box"; // All selected
    } else if (selected.length > 0) {
      return "indeterminate_check_box"; // Some selected
    } else {
      return "check_box_outline_blank";
    }
  };

  return (
    <div className="case-list-actions">
      <a href="#" onClick={toggleSelected}>
        <i
          className={
            "material-icons check-box " + (selectUnchecked ? "unchecked" : "")
          }>
          {selectToggleIcon()}
        </i>
      </a>
      <SimpleSelect
        name="case_processor"
        inlineLabel="Endre saksbehandler"
        options={options.caseProcessors.map(stringOption)}
        value=""
        disabled={selected.length === 0}
        onChange={handleChangeCaseProcessor}
      />
      <SimpleSelect
        name="status"
        inlineLabel="Endre status"
        options={options.statuses}
        value=""
        disabled={selected.length === 0}
        onChange={handleChangeStatus}
      />
      <SimpleSelect
        name="archived"
        inlineLabel="Endre åpen/arkivert"
        options={[
          ["Åpen", "open"],
          ["Arkivert", "archived"]
        ]}
        value=""
        disabled={selected.length === 0}
        onChange={handleChangeArchived}
      />
      <button
        disabled={selected.length === 0}
        onClick={handleLockRecommendations}>
        Lås innstillinger
      </button>
      <button disabled={selected.length === 0} onClick={handleLockDecisions}>
        Lås vedtak
      </button>
      <ExportButton />
    </div>
  );
}
