import { csrfToken } from "../../lib/request";
import useCaseListContext from "./useCaseListContext";

export default function ExportButton() {
  const { count, state } = useCaseListContext();
  const { selected } = state;

  return (
    <form
      action="/saksbehandler/saker/export.xlsx"
      method="POST"
      className="export-form">
      <input name="authenticity_token" type="hidden" value={csrfToken()} />
      {selected.length > 0 && (
        <input name="ids" type="hidden" value={selected.map((id) => `${id}`)} />
      )}
      {selected.length === 0 && (
        <input name="state" type="hidden" value={JSON.stringify(state)} />
      )}
      <button className="export" type="submit">
        Eksporter til Excel ({selected.length > 0 ? selected.length : count})
      </button>
    </form>
  );
}
