import { tabPath } from "./persistence";
import useCaseFormContext from "./useCaseFormContext";

export default function Tabs() {
  const { state, dispatch, preview } = useCaseFormContext();
  const tabs = state.form.tabs;

  const handleClick = (index: number) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "changeTab", payload: index });
  };

  return (
    <div className="tabs">
      <div className="inner">
        {tabs.map((tab, index) => (
          <a
            key={`tab-button-${index}`}
            className={index == state.currentTab ? "current" : ""}
            href={tabPath(state, tab, preview)}
            onClick={handleClick(index)}>
            {tab.name}
          </a>
        ))}
      </div>
    </div>
  );
}
