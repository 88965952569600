import { Fragment } from "react";
import Sent from "./Sent";
import Tab from "./Tab";
import Tabs from "./Tabs";
import useCaseFormContext from "./useCaseFormContext";

export default function Form() {
  const { state } = useCaseFormContext();
  const { form } = state;

  if (state.submitted) {
    return <Sent />;
  }

  if (state.summary) {
    return (
      <Fragment>
        {form.tabs.map((t, i) => (
          <Tab key={i} tab={t} />
        ))}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Tabs />
      <Tab tab={form.tabs[state.currentTab]} />
    </Fragment>
  );
}
