import FlagToggleButton from "./FlagToggleButton";
import { SimpleSelect, stringOption } from "../FormInput";
import useCaseListContext from "./useCaseListContext";

export default function Filter() {
  const { count, state, dispatch, options } = useCaseListContext();

  const { form, caseProcessor, priority, status, flagged, archived } =
    state.filter;

  const { caseProcessors, forms, statuses, priorities } = options;

  const handleChange = (payload: Partial<Cases.Filter>) => {
    dispatch({ type: "updateFilter", payload: payload });
  };

  return (
    <div className="case-list-filter">
      <div className="selects">
        <SimpleSelect
          label="Saksbehandler"
          name="caseProcessor"
          blankOption="Alle saksbehandlere"
          options={[
            ["Ingen saksbehandler", "none"],
            ...caseProcessors.map(stringOption)
          ]}
          value={caseProcessor}
          onChange={(id: string) => handleChange({ caseProcessor: id })}
        />
        <SimpleSelect
          label="Ordning"
          name="formName"
          blankOption="Alle ordninger"
          options={forms.map((f) => [f.name, f.slug])}
          value={form}
          onChange={(f: string) => handleChange({ form: f })}
        />
        <SimpleSelect
          label="Status"
          name="status"
          blankOption="Alle statuser"
          options={statuses}
          value={status}
          onChange={(s: string) => handleChange({ status: s })}
        />
        <SimpleSelect
          label="Prioritet"
          name="prioritet"
          blankOption="Alle saker"
          options={priorities.map(stringOption)}
          value={priority}
          onChange={(p: string) => handleChange({ priority: p })}
        />
        <SimpleSelect
          label="Åpne/arkivert"
          name="archived"
          options={options.archived.map((a) => [a.name, a.value])}
          value={archived}
          onChange={(a: Cases.ArchiveValue) => handleChange({ archived: a })}
        />
        <FlagToggleButton
          value={flagged}
          onChange={(f: boolean) => handleChange({ flagged: f })}
        />
        <span className="filtered-count">{count}</span>
      </div>
    </div>
  );
}
