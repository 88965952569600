type Props = {
  case: Pick<Cases.Case, "status">;
};

export default function CaseIcon(props: Props) {
  const iconName = () => {
    switch (props.case.status) {
      case "registered":
        return undefined;
      case "documentation_requested":
      case "complaint_received":
      case "agreement_requested":
      case "advance_requested":
      case "report_requested":
        return "error_outline";
      default:
        return "check";
    }
  };

  const name = iconName();
  if (name) {
    return <i className="material-icons case-icon">{name}</i>;
  } else {
    return "";
  }
}
