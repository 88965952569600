import { useEffect, useReducer } from "react";

type StoredState = Record<string, { filter: Cases.Filter; sort: Cases.Sort }>;

type Props = {
  forms: Cases.Form[];
  statuses: CaseStatusOption[];
  caseProcessors?: CaseProcessorOption[];
  priorities?: Priority[];
  actions?: boolean;
  filter?: boolean;
};

function reducer(state: Cases.State, action: Cases.Action): Cases.State {
  const { filter, selected, sort } = state;

  switch (action.type) {
    case "clearSelected":
      return { ...state, selected: [] };
    case "setPage":
      return { ...state, page: action.payload, selected: [] };
    case "setSearch":
      return { ...state, search: action.payload, page: 1 };
    case "setSelected":
      return { ...state, selected: action.payload };
    case "toggleSelected": {
      if (selected.indexOf(action.payload) === -1) {
        return { ...state, selected: [...selected, action.payload] };
      } else {
        return {
          ...state,
          selected: selected.filter((s) => s !== action.payload)
        };
      }
    }
    case "toggleSort":
      return {
        ...state,
        sort: {
          column: action.payload,
          reverse: sort.column === action.payload ? !sort.reverse : false
        },
        page: 1,
        selected: []
      };
    case "updateFilter":
      return {
        ...state,
        filter: { ...filter, ...action.payload },
        page: 1,
        selected: []
      };
  }
}

function storedState(): StoredState {
  if (typeof window !== "undefined" && window.localStorage) {
    return JSON.parse(window.localStorage.caseListState || "{}");
  } else {
    return {};
  }
}

function restoreState(state: Cases.State, storeKey?: string): Cases.State {
  if (!storeKey || !storedState()[storeKey]) {
    return state;
  }

  const restored = storedState()[storeKey];
  return {
    ...state,
    filter: { ...state.filter, ...restored.filter },
    sort: { ...state.sort, ...restored.sort }
  };
}

function initialState(storeKey?: string): Cases.State {
  const state: Cases.State = {
    filter: {
      form: "",
      status: "",
      caseProcessor: "",
      priority: "",
      flagged: false,
      archived: "open"
    },
    page: 1,
    search: "",
    selected: [],
    sort: { column: "submitted_at", reverse: false }
  };

  return restoreState(state, storeKey);
}

export default function useCaseList(
  props: Props,
  storeKey?: string
): [Cases.State, React.Dispatch<Cases.Action>, Cases.Options] {
  const [state, dispatch] = useReducer(reducer, {}, () =>
    initialState(storeKey)
  );

  // Save sort state to localStorage
  useEffect(() => {
    if (typeof window !== "undefined" && window.localStorage && storeKey) {
      window.localStorage.caseListState = JSON.stringify({
        ...storedState(),
        [storeKey]: { filter: state.filter, sort: state.sort }
      });
    }
  }, [storeKey, state.filter, state.sort]);

  const options: Cases.Options = {
    archived: [
      { name: "Åpne", value: "open" },
      { name: "Arkivert", value: "archived" },
      { name: "Alle", value: "all" }
    ],
    forms: props.forms,
    statuses: props.statuses,
    filter: props.filter || false,
    actions: (props.filter && props.actions) || false,
    caseProcessors: props.caseProcessors || [],
    priorities: props.priorities || []
  };

  return [state, dispatch, options];
}
