import { useReducer, useState } from "react";
import { uniqueId } from "lodash";
import { EditorState } from "draft-js";

import Options from "./Options";
import {
  RichTextArea,
  richTextFromHTML,
  richTextToHTML,
  TextField
} from "../FormInput";
import { StoreAction } from "./store";

type Props = {
  field: Form.OptionsField;
  closeModal: (evt: React.MouseEvent) => void;
  dispatch: (action: StoreAction) => void;
};

type State = {
  label: string;
  tooltip: EditorState;
};

export type OptionValue<T = Form.Option> = {
  _id: string;
  value: T;
};

export type OptionAction<T = Form.Option> =
  | { type: "add"; payload: { after?: OptionValue<T>; value: T } }
  | { type: "reorder"; payload: OptionValue<T>[] }
  | { type: "delete" | "update"; payload: OptionValue<T> };

function initOptions<T extends Form.Option>(options: T[]): OptionValue<T>[] {
  return options.map((v) => {
    return { _id: uniqueId("option"), value: v };
  });
}

function insertElement<T>(
  state: OptionValue<T>[],
  item: OptionValue<T>,
  after?: OptionValue<T>
) {
  if (after) {
    const index = state.indexOf(after);
    return [...state.slice(0, index + 1), item, ...state.slice(index + 1)];
  } else {
    return [...state, item];
  }
}

function optionsReducer<T extends Form.Option>(
  state: OptionValue<T>[],
  action: OptionAction<T>
): OptionValue<T>[] {
  switch (action.type) {
    case "add":
      return insertElement(
        state,
        { _id: uniqueId("option"), value: action.payload.value },
        action.payload.after
      );
    case "delete":
      return state.filter((o) => o._id !== action.payload._id);
    case "reorder":
      return action.payload;
    case "update":
      return state.map((o) => {
        if (o._id === action.payload._id) {
          return action.payload;
        } else {
          return o;
        }
      });
    default:
      return state;
  }
}

function nonEmpty(item) {
  if (Array.isArray(item)) {
    return item.filter(nonEmpty).length > 0;
  } else {
    return !!item;
  }
}

export default function EditOptionsField(props: Props) {
  const [optionsType] = useState<"string" | "array">(
    typeof props.field.options[0] == "string" ? "string" : "array"
  );

  const [state, setState] = useState<State>({
    label: props.field.label,
    tooltip: richTextFromHTML(props.field.tooltip)
  });

  const [options, dispatchOptions] = useReducer(
    optionsReducer,
    props.field.options,
    initOptions
  );

  const updateState = (newState: Partial<State>) => {
    setState({ ...state, ...newState });
  };

  const save = (evt: React.MouseEvent) => {
    props.dispatch({
      type: "updateField",
      payload: {
        ...props.field,
        ...state,
        options: options.map((o) => o.value).filter(nonEmpty) as Form.Options,
        tooltip: richTextToHTML(state.tooltip)
      }
    });
    props.closeModal(evt);
  };

  return (
    <form className="modal-form">
      <h2>Endre felt</h2>
      <div className="row">
        <TextField
          name="name"
          label="ID"
          value={props.field.name}
          disabled={true}
          size={3}
        />
        <TextField
          name="label"
          label="Label"
          onChange={(val: string) => updateState({ label: val })}
          value={state.label}
          size={9}
        />
      </div>
      <div className="row">
        <Options
          options={options}
          optionsType={optionsType}
          dispatch={dispatchOptions}
        />
      </div>
      <div className="row">
        <RichTextArea
          name="tooltip"
          label="Hjelpetekst"
          onChange={(val: EditorState) => updateState({ tooltip: val })}
          value={state.tooltip}
          links={true}
          simple={true}
          size={12}
        />
      </div>
      <div className="buttons">
        <button type="submit" onClick={save}>
          Oppdater
        </button>
        <button onClick={props.closeModal}>Avbryt</button>
      </div>
    </form>
  );
}
