import { useEffect, useRef } from "react";
import debounce from "debounce";
import useCaseListContext from "./useCaseListContext";

function useValue(value: string) {
  const ref = useRef<HTMLInputElement>();
  useEffect(() => {
    if (ref.current && ref.current.value !== value) {
      ref.current.value = value;
    }
  }, [value]);
  return { ref: ref, defaultValue: value };
}

export default function Search() {
  const { state, dispatch } = useCaseListContext();

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "setSearch", payload: evt.target.value });
  };

  return (
    <div className="search-bar">
      <form>
        <input
          className="search"
          placeholder="Søk i søknader"
          onChange={debounce(handleChange, 250)}
          {...useValue(state.search)}
        />
      </form>
    </div>
  );
}
