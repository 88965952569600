import Block from "./Block";
import Description from "./Description";
import EditTab from "./EditTab";
import EditButton from "./EditButton";
import { StoreAction } from "./store";

type TabProps = {
  tab: Form.Tab;
  dispatch: (action: StoreAction) => void;
};

export default function Tab(props: TabProps) {
  const { name, description, blocks } = props.tab;

  const form = (closeModal: () => void) => {
    return (
      <EditTab
        tab={props.tab}
        closeModal={closeModal}
        dispatch={props.dispatch}
      />
    );
  };

  return (
    <div className="tab">
      <h2 className="border">
        <EditButton form={form} />
        {name}
      </h2>
      {description ? (
        <Description str={description} />
      ) : (
        <div className="description">
          <p className="no-description">Ingen beskrivelse</p>
        </div>
      )}
      {blocks.map((b) => (
        <Block block={b} dispatch={props.dispatch} key={b._id} />
      ))}
    </div>
  );
}
