import { useState } from "react";
import Form, { Props as ParentProps } from "./Form";
import { Select } from "../FormInput";
import { formatDate } from "../../lib/dates";

type Props = ParentProps & {
  decision: Decision;
  template?: string;
  templates: MessageTemplate[];
};

type TemplateData = {
  case_number?: string;
  decision?: Decision;
};

function findTemplate(id: string, templates: MessageTemplate[]) {
  return id && templates.filter((t) => t.id == id)[0];
}

function renderTemplate(
  template: MessageTemplate | null,
  opts: TemplateData = {}
) {
  if (!template) {
    return "";
  }
  const str = template.body
    .replace("{saksnr}", opts.case_number || "SAKSNUMMER MANGLER")
    .replace("{vedtak}", opts.decision.body || "VEDTAK MANGLER")
    .replace("{dato}", formatDate(new Date()));
  return str;
}

export default function SaksbehandlerCaseMessageForm(props: Props) {
  const { case_number, decision, templates } = props;

  const [body, setBody] = useState(props.body || "");
  const [template, setTemplate] = useState(props.template || "");

  const templateOptions: FormInput.Option[] = templates.map((t) => [
    t.name,
    t.id
  ]);

  const changeTemplate = (id: string, nextBody: string) => {
    setTemplate(id);
    setBody(nextBody);
  };

  const handleTemplateChange = (id: string) => {
    const data = { case_number: case_number, decision: decision };

    const prevBody = renderTemplate(findTemplate(template, templates), data);
    const nextBody = renderTemplate(findTemplate(id, templates), data);

    if (body.trim() !== "" && prevBody !== body) {
      if (
        confirm(
          "Du har gjort endringer i meldingen. Sikker på at du vil bytte mal?"
        )
      ) {
        changeTemplate(id, nextBody);
      }
    } else {
      changeTemplate(id, nextBody);
    }
  };

  const header = (
    <div className="header">
      <h3>Melding til søker</h3>
      <p>
        Med varsel til {props.email} angående søknad {props.case_number}
      </p>
    </div>
  );

  return (
    <Form
      {...props}
      header={header}
      messageType="admin"
      body={body}
      setBody={setBody}
      storageScope={`SaksbehandlerCaseMessageForm:${props.case_id}`}>
      <div className="row">
        <Select
          name="case_message[template]"
          label="Mal"
          blankOption="Velg mal"
          options={templateOptions}
          onChange={handleTemplateChange}
          size={4}
          value={template}
        />
      </div>
    </Form>
  );
}
