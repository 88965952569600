import { useState } from "react";

import { csrfToken } from "../lib/request";

type Props = {
  case: Pick<Cases.Case, "flagged">;
  url: string;
};

export default function FlagToggle(props: Props) {
  const [flagged, setFlagged] = useState(props.case.flagged);
  const flagClass = flagged ? "flagged" : "not-flagged";

  const save = (flagged: boolean) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", props.url);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.setRequestHeader("X-CSRF-Token", csrfToken());
    xhr.onload = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        const response = JSON.parse(xhr.responseText) as Cases.Resource;
        setFlagged(response.case.flagged);
      }
    };
    xhr.send(JSON.stringify({ case: { flagged: flagged } }));
  };

  const toggleFlagged = (evt: React.MouseEvent) => {
    evt.preventDefault();
    save(!flagged);
  };

  return (
    <a href="#" className={"flag-toggle " + flagClass} onClick={toggleFlagged}>
      {flagged ? "Flagget" : "Flagg"}
    </a>
  );
}
