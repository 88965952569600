import { FC, useState } from "react";

import Modal from "../Modal";

type EditButtonProps = {
  form: FC;
};

export default function EditButton(props: EditButtonProps) {
  const [editing, updateEditing] = useState(false);

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    updateEditing(true);
  };

  const closeModal = (evt?: React.MouseEvent) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    updateEditing(false);
  };

  return (
    <div className="edit-button">
      {editing && <Modal onClose={closeModal}>{props.form(closeModal)}</Modal>}
      <button onClick={handleClick}>
        <i className="material-icons">edit</i>
      </button>
    </div>
  );
}
