import { useRef, useState } from "react";
import {
  ContentBlock,
  ContentState,
  DraftEditorCommand,
  DraftHandleValue,
  Editor,
  EditorState,
  CompositeDecorator,
  RichUtils
} from "draft-js";
import {
  onDraftEditorCopy,
  onDraftEditorCut,
  handleDraftEditorPastedText
} from "draftjs-conductor";
import styleMap from "./styleMap";
import Buttons from "./Buttons";
import Link from "./Link";
import "draft-js/dist/Draft.css";

type Props = {
  onChange: (state: EditorState) => void;
  editorState: EditorState;
  links?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  simple?: boolean;
};

export const decorator = new CompositeDecorator([
  { strategy: findLinkEntities, component: Link }
]);

function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (entityKey !== null) {
      return contentState.getEntity(entityKey).getType() === "LINK";
    }
  }, callback);
}

export default function RichEditor(props: Props) {
  const { editorState, links, placeholder, readOnly, simple } = props;

  const [focus, setFocus] = useState(false);
  const editorRef = useRef(null);

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);

  const handleChange = (editorState: EditorState) => {
    if (props.onChange) {
      props.onChange(editorState);
    }
  };

  const handleKeyCommand = (
    command: DraftEditorCommand,
    editorState: EditorState
  ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  const handlePastedText = (
    text: string,
    html: string,
    editorState: EditorState
  ): DraftHandleValue => {
    const newState = handleDraftEditorPastedText(html, editorState);

    if (newState) {
      handleChange(newState);
      return "handled";
    }

    return "not-handled";
  };

  const handleReturn = (evt: React.KeyboardEvent): DraftHandleValue => {
    if (evt.shiftKey) {
      handleChange(RichUtils.insertSoftNewline(editorState));
      return "handled";
    }
    return "not-handled";
  };

  return (
    <div className="rich-editor">
      <Buttons
        editorState={editorState}
        onChange={handleChange}
        readOnly={readOnly}
        links={links}
        simple={simple}
      />
      <div className={"content " + (focus && "focus")}>
        <Editor
          ref={editorRef}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          handleReturn={handleReturn}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onCopy={onDraftEditorCopy}
          onCut={onDraftEditorCut}
          handlePastedText={handlePastedText}
          placeholder={placeholder}
          readOnly={readOnly}
          customStyleMap={styleMap}
        />
      </div>
    </div>
  );
}
