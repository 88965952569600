type DescriptionProps = {
  str: string;
};

export default function Description(props: DescriptionProps) {
  const { str } = props;

  if (!str) {
    return <></>;
  } else if (str.match(/</)) {
    return (
      <div className="description" dangerouslySetInnerHTML={{ __html: str }} />
    );
  } else {
    return (
      <div className="description">
        <p>{str}</p>
      </div>
    );
  }
}
