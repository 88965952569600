import { Fragment } from "react";
import RelativeTime from "../RelativeTime";
import CaseIcon from "../CaseIcon";
import useCaseListContext from "./useCaseListContext";
import { formatCompactDateTime } from "../../lib/dates";

type Props = {
  case: Cases.Case;
};

type ColumnProps = {
  className?: string;
  onClick?: (evt: React.MouseEvent) => void;
};

function formatNumber(num: number) {
  return new Intl.NumberFormat("nb-NO", { style: "decimal" }).format(num);
}

export default function Case(props: Props) {
  const { state, dispatch, options } = useCaseListContext();
  const { forms, statuses } = options;
  const kase = props.case;

  const caseProcessor =
    (kase.case_processor && kase.case_processor.name) || "-";
  const formName = forms.filter((f) => f.slug == kase.form_slug)[0].name;
  const statusName = statuses.filter((o) => o[1] == kase.status)[0][0];
  const isSelected = state.selected.indexOf(kase.id) !== -1;

  const toggleSelected = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "toggleSelected", payload: kase.id });
  };

  const Column = ({
    children,
    className,
    onClick
  }: React.PropsWithChildren<ColumnProps>) => {
    return (
      <td className={className}>
        <a href={kase.url} onClick={onClick}>
          {children}
        </a>
      </td>
    );
  };

  return (
    <tr className={kase.flagged ? "flagged" : ""}>
      {options.actions && (
        <Column onClick={toggleSelected}>
          <i
            className={
              "material-icons check-box " + (isSelected ? "" : "unchecked")
            }>
            {isSelected ? "check_box" : "check_box_outline_blank"}
          </i>
        </Column>
      )}
      <Column className="case-number">
        {kase.case_number}
        <br />
        {formatCompactDateTime(kase.submitted_at)}
      </Column>
      <Column>
        <span className="organization-name">
          <b>{kase.organization_name}</b>
        </span>
        <span className="organization-number">{kase.organization_number}</span>
        <br />
        {kase.contact_name}
      </Column>
      <Column>
        {formName}
        <br />
        {formatNumber(kase.total_budget)} kr
      </Column>
      <Column className="status process-status">
        {kase.process_status && (
          <Fragment>
            {kase.process_status.locked && (
              <i className="material-icons">lock</i>
            )}
            {!kase.process_status.locked && (
              <i className="material-icons">lock_open</i>
            )}
            <b>{kase.process_status.label}</b>
            <br />
            {kase.process_status.sum && kase.process_status.sum > 0 && (
              <Fragment>{formatNumber(kase.process_status.sum)} kr</Fragment>
            )}
          </Fragment>
        )}
      </Column>
      <Column className="status case-status">
        <CaseIcon case={kase} />
        <b>{statusName}</b>
        <br />
        {caseProcessor}
      </Column>
      <Column className="relative-time">
        <RelativeTime time={kase.updated_at} />
      </Column>
      <Column className="read-status">
        {kase.unread_messages > 0 && <span className="unread-marker" />}
      </Column>
    </tr>
  );
}
