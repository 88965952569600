import Item from "./Item";
import useCaseListContext from "./useCaseListContext";

type Props = {
  cases: Cases.Case[];
  isLoading?: boolean;
};

export default function Table({ cases, isLoading }: Props) {
  const { state, dispatch, options } = useCaseListContext();
  const { sort } = state;

  const sortColumn = (label: string, column: Cases.SortColumn) => {
    const classes = ["sortable"];
    if (sort.column == column) {
      classes.push("current");
      if (sort.reverse) {
        classes.push("reverse");
      }
    }
    return (
      <th className={classes.join(" ")}>
        <a href="#" onClick={(evt) => toggleSort(column, evt)}>
          {label}
        </a>
      </th>
    );
  };

  const toggleSort = (column: Cases.SortColumn, evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "toggleSort", payload: column });
  };

  const classNames = ["cases list", "linked-rows", "flaggable"];
  if (isLoading) {
    classNames.push("loading");
  }

  return (
    <div className="table-wrapper">
      <table className={classNames.join(" ")}>
        <thead>
          <tr>
            {options.actions && <th></th>}
            {sortColumn("Saksnummer", "submitted_at")}
            {sortColumn("Søker", "organization_name")}
            {sortColumn("Ordning", "form_name")}
            {sortColumn("Innstilling", "process_status")}
            {sortColumn("Status", "status")}
            {sortColumn("Sist aktiv", "updated_at")}
            {sortColumn("Ulest", "unread_messages")}
          </tr>
        </thead>
        <tbody>
          {cases.map((c) => (
            <Item key={c.id} case={c} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
