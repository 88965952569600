import { useRef } from "react";

import Pagination from "../Pagination";
import Actions from "./Actions";
import Table from "./Table";
import Search from "./Search";
import useCaseListContext from "./useCaseListContext";

type Props = {
  cases: Cases.Case[];
  pageCount: number;
  isLoading: boolean;
};

export default function List({ cases, pageCount, isLoading }: Props) {
  const { state, dispatch, options } = useCaseListContext();
  const scrollRef = useRef<HTMLDivElement>();

  const scrollToCases = () => {
    if (!scrollRef.current || !window) {
      return;
    }

    const elem = scrollRef.current,
      rect = elem.getBoundingClientRect(),
      bodyRect = document.body.getBoundingClientRect(),
      offset = rect.top - bodyRect.top - 40;

    if (window.scrollY > offset) {
      window.scrollTo(0, offset);
    }
  };

  const handlePageChange = (selectedItem: { selected: number }) => {
    dispatch({ type: "setPage", payload: selectedItem.selected + 1 });

    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    scrollToCases();
  };

  const pagination = (
    <Pagination
      hrefBuilder={() => ""}
      onPageChange={handlePageChange}
      page={state.page}
      pageCount={pageCount}
    />
  );

  return (
    <div className="saksbehandler-cases" ref={scrollRef}>
      <Search />
      {pagination}
      {options.actions && <Actions cases={cases} />}
      <Table cases={cases} isLoading={isLoading} />
      {pagination}
    </div>
  );
}
