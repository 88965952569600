import { jsonFetchHeaders } from "../../lib/request";

type BackendResponse = {
  id: number;
};

export async function getCaseId(
  state: CaseForm.State,
  dispatch: React.Dispatch<CaseForm.Action>
) {
  if (state.id) {
    return state.id;
  } else {
    const { id } = await postCase(state, { case: { data: {} } });
    dispatch({ type: "setId", payload: id });
    return id;
  }
}

export function saveUrl(state: CaseForm.State, json?: boolean) {
  const extension = json ? ".json" : "";
  if (state.id) {
    return `/saker/${state.id}${extension}`;
  } else {
    return `/saker/${state.form.slug}${extension}`;
  }
}

export function tabPath(
  state: CaseForm.State,
  tab: Form.Tab,
  preview: boolean
) {
  if (preview) {
    return `#${tab.id}`;
  } else if (state.id) {
    return `/saker/${state.id}/endre/${tab.id}`;
  } else {
    return `/saker/ny/${state.form.slug}/${tab.id}`;
  }
}

export function save(
  state: CaseForm.State,
  dispatch: React.Dispatch<CaseForm.Action>,
  success: () => void,
  handleError: (err: Error) => void
) {
  postCase(state, { case: { data: state.data } })
    .then((json) => {
      dispatch({ type: "setSaved", payload: json.id });
      success();
    })
    .catch(handleError);
}

export function submit(
  state: CaseForm.State,
  dispatch: React.Dispatch<CaseForm.Action>,
  success: () => void,
  handleError: (err: Error) => void
) {
  dispatch({ type: "setSubmitted" });
  postCase(state, { case: { data: state.data, submitted: true } })
    .then((json) => {
      dispatch({ type: "setSaved", payload: json.id });
      success();
    })
    .catch(handleError);
}

export async function postCase(state: CaseForm.State, body = {}) {
  const response = await fetch(saveUrl(state, true), {
    method: state.id ? "PUT" : "POST",
    headers: jsonFetchHeaders(),
    body: JSON.stringify(body)
  });
  return (await response.json()) as Promise<BackendResponse>;
}
