import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import CaseList, { Props } from "./Cases/CaseList";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: Infinity }
  }
});

export default function Cases(props: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <CaseList {...props} />
    </QueryClientProvider>
  );
}
