import { useEffect } from "react";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import qs from "qs";
import { jsonFetchHeaders } from "../../lib/request";

type Response = {
  cases: Cases.Case[];
  meta: { count: number; pageCount: number };
};

type UpdateProps = {
  url: string;
  data: unknown;
};

async function fetchCases(
  endpoint: string,
  state: Cases.State
): Promise<Response> {
  const params = qs.stringify(state);
  const response = await fetch(`${endpoint}?${params}`);
  return await response.json();
}

async function updateCases(props: UpdateProps) {
  const { url, data } = props;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: jsonFetchHeaders()
  });
  return await response.json();
}

export function useCasesMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCases,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cases"] });
    }
  });
}

export default function useCasesQuery(
  endpoint: string,
  state: Cases.State,
  dispatch: React.Dispatch<Cases.Action>
) {
  const query = useQuery({
    queryKey: ["cases", state],
    queryFn: () => fetchCases(endpoint, state),
    placeholderData: keepPreviousData
  });

  useEffect(() => {
    if (query.data && query.data.cases && state.selected.length > 0) {
      const ids = query.data.cases.map((c) => c.id);
      const selected = state.selected.filter((id) => ids.indexOf(id) !== -1);

      if (selected.length !== state.selected.length) {
        dispatch({ type: "setSelected", payload: selected });
      }
    }
  }, [dispatch, query.data, state.selected]);

  return query;
}
