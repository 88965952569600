import { useState } from "react";
import { EditorState } from "draft-js";

import useToastStore from "../stores/useToastStore";
import { useTabStore, prepareData } from "./EditForm/store";
import {
  CheckBox,
  RichTextArea,
  richTextFromHTML,
  richTextToHTML,
  TextField
} from "./FormInput";
import Nav from "./EditForm/Nav";
import Tab from "./EditForm/Tab";
import { StoreAction } from "./EditForm/store";
import { csrfToken, xhr } from "../lib/request";

type EditFormProps = {
  name: string;
  description: string;
  enabled: boolean;
  agreement_deadline: number;
  open: boolean;
  report_deadline: number;
  tabs: Form.Tab[];
  url: string;
};

type EditFormState = {
  name: string;
  description: EditorState;
  enabled: boolean;
  agreement_deadline: string;
  open: boolean;
  report_deadline: string;
};

export default function EditForm(props: EditFormProps) {
  const [tabs, dispatchTabs] = useTabStore(props.tabs);
  const [changed, updateChanged] = useState(false);
  const [currentTabId, changeTab] = useState(tabs[0] && tabs[0]._id);
  const [state, setState] = useState<EditFormState>({
    name: props.name,
    description: richTextFromHTML(props.description),
    enabled: props.enabled,
    agreement_deadline: `${props.agreement_deadline}`,
    open: props.open,
    report_deadline: `${props.report_deadline}`
  });
  const { notice } = useToastStore((state) => state);

  const updateState = (newState: Partial<EditFormState>) => {
    updateChanged(true);
    setState({ ...state, ...newState });
  };

  const currentTab = tabs.filter((t) => t._id == currentTabId)[0];

  const dispatch = (args: StoreAction) => {
    dispatchTabs(args);
    updateChanged(true);
  };

  const save = (evt: React.MouseEvent) => {
    evt.preventDefault();

    const formData = {
      form: {
        name: state.name,
        description: richTextToHTML(state.description),
        enabled: state.enabled,
        open: state.open,
        agreement_deadline: state.agreement_deadline,
        report_deadline: state.report_deadline,
        tabs: prepareData(tabs) as Form.Tab[]
      }
    };

    xhr(props.url, { method: "PUT", authToken: csrfToken() }, () => {
      notice("Dine endringer ble lagret");
      updateChanged(false);
    }).send(JSON.stringify(formData));
  };

  return (
    <div className="edit-form">
      <section>
        <div className="inner">
          <form>
            <div className="row">
              <TextField
                name="name"
                label="Navn"
                onChange={(val: string) => updateState({ name: val })}
                value={state.name}
                size={9}
              />
            </div>
            <div className="row">
              <RichTextArea
                name="description"
                label="Beskrivelse"
                onChange={(val: EditorState) =>
                  updateState({ description: val })
                }
                links={true}
                simple={true}
                value={state.description}
                size={9}
              />
            </div>
            <div className="row">
              <TextField
                name="agreement_deadline"
                label="Avtalefrist (dager)"
                onChange={(val: string) =>
                  updateState({ agreement_deadline: val })
                }
                value={state.agreement_deadline}
                size={3}
              />
              <TextField
                name="report_deadline"
                label="Rapportfrist (dager)"
                onChange={(val: string) =>
                  updateState({ report_deadline: val })
                }
                value={state.report_deadline}
                size={3}
              />
            </div>
            <div className="row">
              <CheckBox
                name="form[enabled]"
                label="Aktiv (vises i oversikt)"
                onChange={(val: boolean) => updateState({ enabled: val })}
                value={state.enabled}
                size={9}
              />
            </div>
            <div className="row">
              <CheckBox
                name="form[open]"
                label="Åpen for søknader"
                onChange={(val: boolean) => updateState({ open: val })}
                value={state.enabled && state.open}
                disabled={!state.enabled}
                size={9}
              />
            </div>
          </form>
        </div>
      </section>
      {tabs.length > 0 && (
        <Nav tabs={tabs} currentTab={currentTab} changeTab={changeTab} />
      )}
      <section>
        <div className="inner">
          {currentTab && <Tab tab={currentTab} dispatch={dispatch} />}
          <div className="buttons">
            <button type="submit" onClick={save} disabled={!changed}>
              Lagre
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
