type VisibilityToggleProps = {
  visible: boolean;
  onClick: (evt: React.MouseEvent) => void;
};

export default function VisibilityToggle(props: VisibilityToggleProps) {
  const icon = props.visible ? "visibility" : "visibility_off";
  return (
    <a
      href="#"
      className="visibility-toggle"
      onClick={props.onClick}
      title="Vis passord">
      <i className="material-icons">{icon}</i>
    </a>
  );
}
