import CaseIcon from "../CaseIcon";

type BottomBarProps = {
  kase: Recommendation.Case;
  changed: boolean;
  locked: boolean;
  policies: Recommendation.Policies;
  save: (evt: React.MouseEvent) => void;
  preview: (evt: React.MouseEvent) => void;
};

export default function BottomBar(props: BottomBarProps) {
  const { kase } = props;

  const saveEnabled = props.policies.update && !props.locked && props.changed;

  return (
    <div className="bottom-bar">
      <div className="inner">
        <div className="info">
          <span>
            <b>{kase.case_number}</b>
          </span>
          <span>{kase.organization_number}</span>
          <span>{kase.contact_name}</span>
          <span>
            <a href={`mailto:${kase.contact_email}`}>{kase.contact_email}</a>
          </span>
          <span>{kase.contact_phone}</span>
          <span>{kase.total_budget}</span>
          <span>{kase.form_name}</span>
          <span className="status">
            <b>
              <CaseIcon case={kase} />
              {kase.status_label}
            </b>
          </span>
        </div>
        <div className="buttons">
          <button onClick={props.preview}>
            <i className="material-icons">preview</i>
            Forhåndsvis
          </button>
          <button type="submit" disabled={!saveEnabled} onClick={props.save}>
            <i className="material-icons">save</i>
            Lagre
          </button>
        </div>
      </div>
    </div>
  );
}
