import { useEffect, useRef, useState, ReactNode } from "react";
import Attachments from "../Attachments";
import { FileUploader, TextArea } from "../FormInput";
import { Attachment } from "../../lib/attachments";
import { csrfToken } from "../../lib/request";
import useMaybeControlledValue from "../useMaybeControlledValue";

export type Props = {
  autoFocus: boolean;
  case_number: string;
  url: string;
  case_id: number;
  email?: string;
  body?: string;
  header?: ReactNode;
  messageType?: string;
  sendButtonLabel?: string;
  setBody?: (body: string) => void;
  submission?: Submission;
  storageScope?: string;
};

type StoredState = {
  body: string;
  files: Attachment[];
};

function Header(props: { case_number: string }) {
  return (
    <div className="header">
      <h3>Melding til Kulturrom</h3>
      <p>Angående søknad {props.case_number}</p>
    </div>
  );
}

export default function Form(props: React.PropsWithChildren<Props>) {
  const { submission } = props;

  const bodyRef = useRef<HTMLTextAreaElement>();

  const [body, setBody] = useMaybeControlledValue<string>({
    value: props.body || "",
    onChange: props.setBody
  });
  const [files, setFiles] = useState<Attachment[]>([]);

  const storageScope = props.storageScope || `CaseMessageForm:${props.case_id}`;

  // Restore state
  useEffect(() => {
    if ("localStorage" in window && window.localStorage.getItem(storageScope)) {
      const nextState = JSON.parse(
        localStorage.getItem(storageScope)
      ) as StoredState;
      setBody(nextState.body);
      setFiles(nextState.files);
    }
  }, [setBody, storageScope]);

  // Save state
  useEffect(() => {
    if ("localStorage" in window) {
      window.localStorage.setItem(
        storageScope,
        JSON.stringify({ body: body, files: files })
      );
    }
  }, [body, files, storageScope]);

  const handleSubmit = () => {
    if (window.localStorage) {
      window.localStorage.removeItem(storageScope);
    }
  };

  const isValid = body.trim() !== "";

  return (
    <form
      action={props.url}
      method="post"
      acceptCharset="UTF-8"
      className="case-message-form rounded-box"
      onSubmit={handleSubmit}>
      {props.header || <Header case_number={props.case_number} />}
      <input type="hidden" name="authenticity_token" value={csrfToken()} />
      <input
        type="hidden"
        name="case_message_type"
        value={props.messageType || "applicant"}
      />
      {props.children}
      <div className="row">
        <TextArea
          autoFocus={props.autoFocus}
          name="case_message[body]"
          label="Melding"
          onChange={setBody}
          size={8}
          value={body}
          inputRef={bodyRef}
        />
      </div>
      {submission && (
        <div className="row">
          <input type="hidden" name="submission" value={submission.global_id} />
          <Attachments submission={submission} />
        </div>
      )}
      <div className="row">
        <FileUploader
          name="case_message[data][attachments]"
          files={files}
          caseId={props.case_id}
          onChange={(v: Attachment[]) => setFiles(v)}
          size={8}
        />
      </div>
      <div className="buttons tight">
        <button type="submit" disabled={!isValid}>
          <i className="material-icons">send</i>
          {props.sendButtonLabel || "Send melding"}
        </button>
      </div>
    </form>
  );
}
