import { ChangeEvent } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { OptionAction, OptionValue } from "./EditOptionsField";

type AddOptions<T> = { after: OptionValue<T> };

type Props<T = Form.Option> = {
  addOption: (opts: AddOptions<T>) => void;
  option: OptionValue<T>;
  dispatch: (action: OptionAction<T>) => void;
};

type OptionProps = Omit<Props, "addOption">;

export default function Option(props: Props) {
  if (typeof props.option.value === "string") {
    return <StringOption {...(props as Props<string>)} />;
  } else {
    return <ArrayOption {...(props as Props<[string, string, string]>)} />;
  }
}

function OptionWrapper(props: React.PropsWithChildren<OptionProps>) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.option._id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleRemove = (evt: React.MouseEvent) => {
    evt.preventDefault();
    props.dispatch({ type: "delete", payload: props.option });
  };

  return (
    <div
      className="edit-option"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}>
      {props.children}
      <button onClick={handleRemove}>
        <i className="material-icons">delete</i>
      </button>
    </div>
  );
}

function StringOption(props: Props<string>) {
  const value = props.option.value;

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    props.dispatch({
      type: "update",
      payload: { ...props.option, value: evt.target.value }
    });
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.which === 13) {
      evt.preventDefault();
      props.addOption({ after: props.option });
    }
  };

  return (
    <OptionWrapper option={props.option} dispatch={props.dispatch}>
      <input
        type="text"
        autoFocus
        value={value}
        placeholder="Navn"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
      />
    </OptionWrapper>
  );
}

function ArrayOption(props: Props<[string, string, string]>) {
  const [label, value, calcValue] = props.option.value;

  const handleChange =
    (index: number) => (evt: ChangeEvent<HTMLInputElement>) => {
      const newValue = [...props.option.value] as [string, string, string];
      newValue[index] = evt.target.value;
      props.dispatch({
        type: "update",
        payload: { ...props.option, value: newValue }
      });
    };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.which === 13) {
      evt.preventDefault();
      props.addOption({ after: props.option });
    }
  };

  return (
    <OptionWrapper option={props.option} dispatch={props.dispatch}>
      <input
        type="text"
        autoFocus
        value={label}
        placeholder="Navn"
        onKeyDown={handleKeyDown}
        onChange={handleChange(0)}
      />
      <input
        type="text"
        value={value}
        placeholder="Verdi"
        onKeyDown={handleKeyDown}
        onChange={handleChange(1)}
      />
      <input
        type="text"
        value={calcValue}
        placeholder="Beregningsverdi"
        onKeyDown={handleKeyDown}
        onChange={handleChange(2)}
      />
    </OptionWrapper>
  );
}
