import { useRef } from "react";
import { csrfToken } from "../../lib/request";

type Props = {
  selected: MeetingCase.MeetingCase[];
  meeting_cases: MeetingCase.MeetingCase[];
  remove_meeting_cases_url: string;
  updateSelection: (cases: MeetingCase.MeetingCase[]) => void;
};

export default function Actions(props: Props) {
  const { meeting_cases, selected, remove_meeting_cases_url, updateSelection } =
    props;
  const removeRef = useRef<HTMLFormElement>();

  const allSelected = () => {
    return meeting_cases.filter((c) => !selected.includes(c)).length == 0;
  };

  const selectToggleIcon = () => {
    if (allSelected()) {
      return "check_box";
    } else if (selected.length > 0) {
      return "indeterminate_check_box";
    } else {
      return "check_box_outline_blank";
    }
  };

  const removeCases = (evt: React.MouseEvent) => {
    evt.preventDefault();
    removeRef.current.submit();
  };

  const toggleSelected = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (selected.length === 0) {
      updateSelection(meeting_cases);
    } else {
      updateSelection([]);
    }
  };

  return (
    <div className="case-list-actions">
      <a href="#" onClick={toggleSelected}>
        <i className="material-icons check-box">{selectToggleIcon()}</i>
      </a>
      <form action={remove_meeting_cases_url} method="post" ref={removeRef}>
        <input name="authenticity_token" type="hidden" value={csrfToken()} />
        <input name="_method" type="hidden" value="delete" />
        <input
          name="meeting_case_ids"
          type="hidden"
          value={selected.map((mc) => mc.meeting_case_id).join(",")}
        />
        <button disabled={selected.length === 0} onClick={removeCases}>
          <i className="material-icons">remove_circle_outline</i>
          Fjern fra saksliste
        </button>
      </form>
    </div>
  );
}
