import Block from "./Block";
import Buttons from "./Buttons";
import Description from "./Description";
import ValidationErrors from "./ValidationErrors";
import { invalidFields } from "./validations";
import { saveUrl } from "./persistence";
import { csrfToken } from "../../lib/request";
import useCaseFormContext from "./useCaseFormContext";

type Props = {
  tab: Form.Tab;
};

export default function Tab({ tab }: Props) {
  const { state, preview } = useCaseFormContext();

  const invalid = invalidFields(state);

  return (
    <section>
      <div className="inner">
        <form method="post" action={saveUrl(state)}>
          <input name="utf8" type="hidden" value="✓" />
          <input
            type="hidden"
            name="_method"
            value={state.id ? "patch" : "post"}
          />
          <input type="hidden" name="authenticity_token" value={csrfToken()} />
          {tab.submit && <ValidationErrors fields={invalid} />}
          <h2 className="border">{tab.name}</h2>
          {!state.summary && <Description text={tab.description} />}
          {tab.blocks.map((block, i) => (
            <Block block={block} readonly={state.summary} key={i} />
          ))}
          {!state.summary && !preview && (
            <Buttons invalid={invalid} tab={tab} />
          )}
        </form>
      </div>
    </section>
  );
}
