import ReportForm from "./ReportForm";

type Props = {
  report: Report.Report;
};

export default function ShowReport(props: Props) {
  return (
    <ReportForm
      report={props.report}
      attributes={props.report.data}
      locked={true}
    />
  );
}
