type Props = {
  id: string;
  tab: Recommendation.InterfaceTab;
};

export default function Tab({
  id,
  tab,
  children
}: React.PropsWithChildren<Props>) {
  if (id == tab.id) {
    return <div className="tab">{children}</div>;
  } else {
    return <></>;
  }
}
