import useCaseFormContext from "./useCaseFormContext";

type Props = {
  fields: Form.Field[];
};

export default function ValidationErrors({ fields }: Props) {
  const { dispatch } = useCaseFormContext();

  const handleGotoField = (field: Form.Field) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "gotoField", payload: field });
  };

  if (fields.length == 0) {
    return "";
  }

  return (
    <div className="validation-errors">
      <i className="material-icons error-icon">info</i>
      <p>
        Søknaden kan ikke sendes inn.
        <br />
        Følgende felter mangler eller har feil:
      </p>
      <ul>
        {fields.map((f, i) => (
          <li key={i}>
            <a href="#" onClick={handleGotoField(f)}>
              {f.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
