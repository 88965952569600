import { EditorState, RichUtils } from "draft-js";

type LinkButtonProps = {
  onChange: (state: EditorState) => void;
  editorState: EditorState;
  disabled: boolean;
};

function addLink(editorState: EditorState, url: string) {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", {
    url: url
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity
  });

  return RichUtils.toggleLink(
    newEditorState,
    newEditorState.getSelection(),
    entityKey
  );
}

function getLinkEntity(editorState: EditorState) {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const linkKey = contentState
    .getBlockForKey(selection.getStartKey())
    .getEntityAt(selection.getStartOffset());

  if (linkKey) {
    const entity = contentState.getEntity(linkKey);
    if (entity.getType() == "LINK") {
      return entity;
    }
  }

  return false;
}

function hasLink(editorState: EditorState) {
  return getLinkEntity(editorState) ? true : false;
}

function removeLink(editorState: EditorState) {
  return RichUtils.toggleLink(editorState, editorState.getSelection(), null);
}

function editLink(editorState: EditorState) {
  let existingUrl = "";
  const linkEntity = getLinkEntity(editorState);
  if (linkEntity) {
    const data = linkEntity.getData() as Immutable.Map<string, unknown>;
    if ("url" in data) {
      existingUrl = data.url as string;
    }
  }

  const url = String(
    prompt("URL (blank for å fjerne link)", existingUrl)
  ).trim();

  if (url !== "") {
    return addLink(editorState, url);
  } else {
    return removeLink(editorState);
  }
}

export default function LinkButton(props: LinkButtonProps) {
  const { disabled, onChange, editorState } = props;

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      onChange(editLink(editorState));
    }
  };

  const preventClick = (evt: React.MouseEvent) => evt.preventDefault();

  const classNames = ["link"];
  if (!editorState.getSelection().isCollapsed() && hasLink(editorState)) {
    classNames.push("active");
  }

  return (
    <button
      className={classNames.join(" ")}
      onClick={preventClick}
      onMouseDown={handleClick}
      title="Link"
      disabled={disabled}>
      <i className="material-icons">link</i>
    </button>
  );
}
