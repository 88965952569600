import { Fragment, useState } from "react";
import CaseMessageForm from "./CaseMessageForm";
import CaseStatusList from "./CaseStatusList";

type Props = {
  case_message: CaseMessage;
  case_id: number;
  case_number: string;
  case_statuses: CaseMessage[];
  disable_reply: boolean;
};

export default function CaseMessages(props: Props) {
  const { case_message, case_id, case_number, case_statuses, disable_reply } =
    props;

  const [showMessageForm, setShowMessageForm] = useState(false);

  const toggleMessageForm = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setShowMessageForm(!showMessageForm);
  };

  return (
    <Fragment>
      {!disable_reply && !showMessageForm && (
        <div className="buttons tight">
          <button
            type="button"
            className="primary"
            disabled={showMessageForm}
            onClick={toggleMessageForm}>
            <i className="material-icons">edit</i>
            Skriv melding til Kulturrom&hellip;
          </button>
        </div>
      )}
      {!disable_reply && showMessageForm && (
        <CaseMessageForm
          url={case_message.url}
          case_id={case_id}
          case_number={case_number}
          autoFocus={true}
        />
      )}
      <CaseStatusList adminView={false} case_statuses={case_statuses} />
    </Fragment>
  );
}
