import Description from "./Description";
import { getCaseId } from "./persistence";
import {
  Calculation,
  CheckBox,
  FileUploader,
  Radio,
  Select,
  TextArea,
  TextField
} from "../FormInput";
import useCaseFormContext from "./useCaseFormContext";

type Props = {
  field: Form.Field;
  readonly: boolean;
};

export default function Field({ field, readonly }: Props) {
  const { state, dispatch } = useCaseFormContext();
  const data = state.data;

  const value = data[field.name];

  const setValue = (attr: string, val: Form.UpdateValue) => {
    dispatch({ type: "update", payload: { [attr]: val } });
  };

  const fieldOptions = {
    disabled:
      field.disabled ||
      (field.enableIf && data[field.enableIf[0]] != field.enableIf[1]),
    inputRef: field.ref,
    label: field.label,
    name: `case[data][${field.name}]`,
    onChange: (v: Form.UpdateValue) => setValue(field.name, v),
    readonly: readonly,
    tooltip: field.tooltip,
    validations: field.validations
  };

  if (field.type == "text" || (field.type == "select" && readonly)) {
    return (
      <TextField
        {...fieldOptions}
        size={field.size || 4}
        limit={field.limit}
        value={value as string}
      />
    );
  } else if (field.type == "calculation") {
    return (
      <Calculation
        {...fieldOptions}
        size={field.size || 4}
        value={value as string}
      />
    );
  } else if (field.type == "textarea") {
    return (
      <TextArea
        {...fieldOptions}
        size={field.size || 9}
        limit={field.limit}
        value={value as string}
      />
    );
  } else if (field.type == "select") {
    return (
      <Select
        {...fieldOptions}
        options={field.options}
        size={field.size || 4}
        blankOption={field.blank_option || "Velg"}
        value={value as string}
      />
    );
  } else if (field.type == "radio") {
    return (
      <Radio
        {...fieldOptions}
        options={field.options}
        size={field.size || 4}
        value={value as string}
      />
    );
  } else if (field.type == "check") {
    return (
      <CheckBox
        {...fieldOptions}
        size={field.size || 4}
        value={value as boolean}
      />
    );
  } else if (field.type == "files") {
    return (
      <FileUploader
        {...fieldOptions}
        files={(value as Attachment[]) || []}
        size={field.size || 9}
        caseId={() => getCaseId(state, dispatch)}
      />
    );
  } else if (field.type == "description") {
    return (
      <div key={field.description} className="description size-12">
        <Description text={field.description} />
      </div>
    );
  } else {
    return "";
  }
}
