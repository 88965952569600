import { useState } from "react";

import {
  ApplicantCaseMessageForm,
  SaksbehandlerCaseMessageForm
} from "./CaseMessageForm";
import CaseNoteForm from "./CaseNoteForm";
import CaseStatusList from "./CaseStatusList";

type Props = {
  case_message: CaseMessage;
  case_note: CaseNote;
  case_id: number;
  case_number: string;
  case_processors: CaseProcessorOption[];
  case_statuses: Array<CaseMessage | CaseNote>;
  granted_statuses: string[];
  status_options: CaseStatusOption[];
};

export default function SaksbehandlerCaseStatuses(props: Props) {
  const {
    case_message,
    case_note,
    case_id,
    case_number,
    case_processors,
    case_statuses,
    granted_statuses,
    status_options
  } = props;

  const [showMessageForm, setShowMessageForm] = useState<string | null>(null);

  return (
    <div className="with-sidebar">
      <div className="main">
        {case_message && !showMessageForm && (
          <div className="buttons tight">
            {case_message.email && (
              <button
                type="button"
                className="primary"
                onClick={() => setShowMessageForm("admin")}>
                <i className="material-icons">edit</i>
                Skriv melding til søker&hellip;
              </button>
            )}

            <button
              type="button"
              onClick={() => setShowMessageForm("applicant")}>
              <i className="material-icons">edit</i>
              Legg inn melding fra søker&hellip;
            </button>
          </div>
        )}
        {case_message && showMessageForm === "admin" && (
          <SaksbehandlerCaseMessageForm
            url={case_message.url}
            templates={case_message.templates}
            email={case_message.email}
            case_id={case_id}
            case_number={case_number}
            decision={case_message.decision}
            autoFocus={true}
          />
        )}
        {case_message && showMessageForm === "applicant" && (
          <ApplicantCaseMessageForm
            url={case_message.url}
            email={case_message.email}
            case_id={case_id}
            case_number={case_number}
            autoFocus={true}
          />
        )}
        <CaseStatusList
          adminView={true}
          case_statuses={case_statuses}
          status_options={status_options}
        />
      </div>
      <div className="sidebar">
        {case_note && (
          <CaseNoteForm
            case_note={case_note}
            case_processors={case_processors}
            case_id={case_id}
            granted_statuses={granted_statuses}
            status_options={status_options}
          />
        )}
      </div>
    </div>
  );
}
