import { createContext, useContext } from "react";

type Context = {
  state: Cases.State;
  dispatch: React.Dispatch<Cases.Action>;
  options: Cases.Options;
  count: number;
};

export const CaseListContext = createContext<Context>(null);

export default function useCaseListContext() {
  return useContext(CaseListContext);
}
