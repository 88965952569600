type FlagToggleButtonProps = {
  value: boolean;
  onChange: (newValue: boolean) => void;
};

export default function FlagToggleButton(props: FlagToggleButtonProps) {
  const className = props.value ? "flagged" : "unflagged";

  const toggleFlagged = (evt: React.MouseEvent) => {
    evt.preventDefault();
    props.onChange(!props.value);
  };

  return (
    <div className={"flag-toggle-button " + className}>
      <button onClick={toggleFlagged}>Flagged</button>
    </div>
  );
}
