import { useEffect } from "react";

import Header from "./CaseForm/Header";
import Form from "./CaseForm/Form";
import useCaseForm from "./CaseForm/useCaseForm";
import { tabPath } from "./CaseForm/persistence";
import { CaseFormContext } from "./CaseForm/useCaseFormContext";

type HistoryState = {
  currentTab: number;
};

function useCaseFormLocation(
  state: CaseForm.State,
  dispatch: React.Dispatch<CaseForm.Action>,
  preview: boolean
) {
  const setStateFromHistory = (evt: PopStateEvent) => {
    const evtState = evt.state as HistoryState;
    if (typeof evtState === "object" && evtState !== null) {
      evt.preventDefault();
      dispatch({ type: "changeTab", payload: evtState.currentTab });
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", setStateFromHistory);
    return () => window.removeEventListener("popstate", setStateFromHistory);
  });

  useEffect(() => {
    const path = tabPath(state, state.form.tabs[state.currentTab], preview);
    if (document.location.pathname != path && !state.summary && !preview) {
      history.pushState({ currentTab: state.currentTab }, "", path);
    }
  }, [preview, state]);
}

export default function CaseForm(props: CaseForm.Props) {
  const [state, dispatch] = useCaseForm(props);
  const { focusField } = state;

  useCaseFormLocation(state, dispatch, props.preview);

  useEffect(() => {
    if (focusField && focusField.ref.current) {
      focusField.ref.current.focus();
      dispatch({ type: "clearFocusField" });
    }
  }, [focusField, dispatch]);

  return (
    <CaseFormContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
        preview: props.preview
      }}>
      <div className="case-form">
        {!state.summary && <Header />}
        <Form />
      </div>
    </CaseFormContext.Provider>
  );
}
