import { useState } from "react";

import { Attachment } from "../lib/attachments";
import { csrfToken } from "../lib/request";
import {
  CheckBox,
  DateSelect,
  FileUploader,
  Select,
  TextArea,
  stringOption
} from "./FormInput";
import { parseDate } from "./FormInput/DateTimeSelect";

type Props = {
  case_note: CaseNote;
  status_options: CaseStatusOption[];
  granted_statuses: string[];
  case_processors: CaseProcessorOption[];
  case_id: number;
};

type State = {
  case_processor_id: number;
  agreement_due_enabled: boolean;
  agreement_due: Date;
  report_due_enabled: boolean;
  report_due: Date;
  body: string;
  status: string;
};

export default function CaseNoteForm(props: Props) {
  const { case_note } = props;

  const [state, setState] = useState<State>({
    case_processor_id: case_note.case_processor_id || null,
    agreement_due_enabled: case_note.agreement_due_enabled,
    agreement_due: parseDate(
      case_note.agreement_due || case_note.agreement_due_default
    ),
    report_due_enabled: case_note.report_due_enabled,
    report_due: parseDate(case_note.report_due || case_note.report_due_default),
    body: "",
    status: case_note.status
  });
  const [files, setFiles] = useState<Attachment[]>([]);

  const updateState = (newState: Partial<State>) => {
    setState({ ...state, ...newState });
  };

  const isGranted = () => {
    return props.granted_statuses.indexOf(state.status) !== -1;
  };

  const isValid = () => {
    const agreementDue =
      (state.agreement_due_enabled && state.agreement_due) || null;
    const reportDue = (state.report_due_enabled && state.report_due) || null;

    return (
      state.case_processor_id !== case_note.case_processor_id ||
      state.status !== case_note.status ||
      state.body.trim() !== "" ||
      state.agreement_due_enabled !== case_note.agreement_due_enabled ||
      agreementDue !== parseDate(case_note.agreement_due) ||
      state.report_due_enabled !== case_note.report_due_enabled ||
      reportDue !== parseDate(case_note.report_due) ||
      files.length > 0
    );
  };

  return (
    <form
      action={props.case_note.url}
      method="post"
      acceptCharset="UTF-8"
      className="case-note-form rounded-box transparent">
      <h2>Status / Notat</h2>
      <input type="hidden" name="authenticity_token" value={csrfToken()} />
      <div className="row">
        <Select
          name="case_note[status]"
          label="Status"
          options={props.status_options}
          onChange={(v: string) => updateState({ status: v })}
          size={4}
          value={state.status}
        />
      </div>
      {isGranted() && (
        <div className="row">
          <CheckBox
            name="case_note[agreement_due_enabled]"
            label="Avtalefrist"
            value={state.agreement_due_enabled}
            onChange={(v: boolean) => updateState({ agreement_due_enabled: v })}
          />
        </div>
      )}
      {isGranted() && state.agreement_due_enabled && (
        <div className="row">
          <DateSelect
            label="Avtalefrist"
            name="case_note[agreement_due]"
            onChange={(v: Date) => updateState({ agreement_due: v })}
            value={state.agreement_due}
          />
        </div>
      )}
      {isGranted() && (
        <div className="row">
          <CheckBox
            name="case_note[report_due_enabled]"
            label="Rapportfrist"
            value={state.report_due_enabled}
            onChange={(v: boolean) => updateState({ report_due_enabled: v })}
          />
        </div>
      )}
      {isGranted() && state.report_due_enabled && (
        <div className="row">
          <DateSelect
            label="Rapportfrist"
            name="case_note[report_due]"
            onChange={(v: Date) => updateState({ report_due: v })}
            value={state.report_due}
          />
        </div>
      )}
      <div className="row">
        <Select
          name="case_note[case_processor_id]"
          label="Saksbehandler"
          options={props.case_processors.map(stringOption)}
          blankOption={
            props.case_note.case_processor_id ? null : "Ingen saksbehandler"
          }
          onChange={(v: string) =>
            updateState({ case_processor_id: v ? parseInt(v) : null })
          }
          size={4}
          value={`${state.case_processor_id}`}
        />
      </div>
      <div className="row">
        <TextArea
          name="case_note[body]"
          label="Internt notat"
          onChange={(v: string) => updateState({ body: v })}
          size={4}
          value={state.body}
        />
      </div>
      <div className="row">
        <FileUploader
          name="case_note[data][attachments]"
          files={files}
          caseId={props.case_id}
          onChange={setFiles}
          size={4}
        />
      </div>
      <div className="buttons tight">
        <button type="submit" disabled={!isValid()}>
          <i className="material-icons">save</i>
          Lagre
        </button>
      </div>
    </form>
  );
}
