type Props = {
  type: string;
  size?: number;
};

export default function Field(props: React.PropsWithChildren<Props>) {
  const { children, type } = props;

  const size = props.size || 3;

  return <div className={`${type} field size-${size}`}>{children}</div>;
}
