import Description from "./Description";
import useCaseFormContext from "./useCaseFormContext";

export default function Header() {
  const { state } = useCaseFormContext();
  const { form } = state;
  return (
    <section className="intro">
      <div className="inner">
        <h1>{form.name}</h1>
        <Description text={form.description} />
      </div>
    </section>
  );
}
