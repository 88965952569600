import { Fragment, useState } from "react";
import Actions from "./MeetingCases/Actions";
import Item from "./MeetingCases/Item";

type Props = {
  meeting_cases: MeetingCase.MeetingCase[];
  priority_options: Priority[];
  agenda_locked: boolean;
  group_cases: boolean;
  remove_meeting_cases_url: string;
  edit_decisions: boolean;
  show_recommendations: boolean;
  show_decision_body: boolean;
};

type CaseGroup = {
  form_name: string;
  priority: number;
  cases: MeetingCase.MeetingCase[];
};

function formatNumber(num: number) {
  if (num) {
    return new Intl.NumberFormat("nb-NO", { style: "decimal" }).format(num);
  } else {
    return "-";
  }
}

function groupedCases(cases: MeetingCase.MeetingCase[]): CaseGroup[] {
  const groups: CaseGroup[] = [];

  cases.forEach((c) => {
    const key = { form_name: c.form_name, priority: c.priority };
    const lastGroup = groups[groups.length - 1];

    if (
      groups.length > 0 &&
      lastGroup.form_name === key.form_name &&
      lastGroup.priority === key.priority
    ) {
      lastGroup.cases.push(c);
    } else {
      groups.push({ ...key, cases: [c] });
    }
  });

  return groups;
}

function sumCases(cases: MeetingCase.MeetingCase[]) {
  return formatNumber(
    cases
      .filter((c) => c.decision.status === "granted")
      .reduce((s, c) => s + parseInt(c.decision.sum, 10), 0)
  );
}

export default function MeetingCases(props: Props) {
  const {
    agenda_locked,
    edit_decisions,
    group_cases,
    remove_meeting_cases_url,
    show_recommendations,
    show_decision_body
  } = props;

  const priorities = props.priority_options.reduce((obj, o) => {
    obj[o[1]] = o[0];
    return obj;
  }, {});

  const [meetingCases, setMeetingCases] = useState(props.meeting_cases);
  const [selected, setSelected] = useState<MeetingCase.MeetingCase[]>([]);

  const groupName = (group: CaseGroup) => {
    let priorityLabel = "Ingen prioritet";
    if (Object.prototype.hasOwnProperty.call(priorities, group.priority)) {
      priorityLabel = priorities[group.priority] as string;
    }
    return `${group.form_name} - ${priorityLabel}`;
  };

  const updateCase = (newCase: MeetingCase.MeetingCase) => {
    const newCases = meetingCases.map((c) => {
      if (c.meeting_case_id === newCase.meeting_case_id) {
        return newCase;
      } else {
        return c;
      }
    });
    setMeetingCases(newCases);
  };

  const updateSelection = (meeting_cases: MeetingCase.MeetingCase[]) => {
    setSelected(meeting_cases);
  };

  const renderCaseGroups = (cases: MeetingCase.MeetingCase[]) => {
    const groups = groupedCases(cases);

    return (
      <Fragment>
        {groups.map((g, i) => (
          <Fragment key={`group-${i}`}>
            <tr className="subheader">
              <td colSpan={agenda_locked ? 4 : 5}>
                <h3>{groupName(g)}</h3>
              </td>
              <td className="sum">{agenda_locked && sumCases(g.cases)}</td>
            </tr>
            {renderCases(g.cases)}
          </Fragment>
        ))}
      </Fragment>
    );
  };

  const renderCases = (cases: MeetingCase.MeetingCase[]) => {
    return (
      <Fragment>
        {cases.map((c) => (
          <Item
            case={c}
            key={c.id}
            selected={selected}
            show_recommendations={show_recommendations}
            show_decision_body={show_decision_body}
            edit_decisions={edit_decisions}
            updateCase={updateCase}
            updateSelection={agenda_locked ? null : updateSelection}
          />
        ))}
      </Fragment>
    );
  };

  return (
    <div className="meeting-cases-index">
      {!agenda_locked && (
        <Actions
          meeting_cases={meetingCases}
          selected={selected}
          updateSelection={updateSelection}
          remove_meeting_cases_url={remove_meeting_cases_url}
        />
      )}
      <table className="meeting-cases list">
        <tbody>
          {agenda_locked && (
            <tr className="subheader">
              <td className="sum" colSpan={agenda_locked ? 5 : 6}>
                Totalt: {sumCases(meetingCases)}
              </td>
            </tr>
          )}
          {group_cases
            ? renderCaseGroups(meetingCases)
            : renderCases(meetingCases)}
        </tbody>
      </table>
    </div>
  );
}
