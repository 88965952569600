import Description from "./Description";
import Field from "./Field";

type Props = {
  readonly: boolean;
  block: Form.Block;
};

export default function Block(props: Props) {
  const { block, readonly } = props;
  return (
    <div className="block">
      <h3>{block.name}</h3>
      {!readonly && <Description text={block.description} />}
      {block.fields.map((row, rowIndex) => (
        <div className="row" key={rowIndex}>
          {row.map((field, fieldIndex) => (
            <Field key={fieldIndex} field={field} {...props} />
          ))}
        </div>
      ))}
    </div>
  );
}
