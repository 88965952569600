import Filter from "./Filter";
import List from "./List";
import useCaseListState from "./useCaseListState";
import { CaseListContext } from "./useCaseListContext";
import useCasesQuery from "./useCasesQuery";

type BaseProps = {
  url: string;
  forms: Cases.Form[];
  statuses: CaseStatusOption[];
};

export type Props =
  | (BaseProps & { filter?: false })
  | (BaseProps & {
      filter: true;
      caseProcessors: CaseProcessorOption[];
      priorities: Priority[];
      actions: boolean;
    });

export default function CaseList(props: Props) {
  const [state, dispatch, options] = useCaseListState(
    props,
    document.location.pathname
  );

  const { data, isPending, isPlaceholderData } = useCasesQuery(
    props.url,
    state,
    dispatch
  );
  const count = data?.meta?.count;

  return (
    <CaseListContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
        options: options,
        count: count
      }}>
      <div className="filterable-cases">
        {options.filter && <Filter />}
        {data?.cases && (
          <List
            cases={data.cases}
            pageCount={data.meta.pageCount}
            isLoading={isPending || isPlaceholderData}
          />
        )}
      </div>
    </CaseListContext.Provider>
  );
}
