import { ContentState } from "draft-js";
import styleMap from "./styleMap";

type Props = {
  contentState: ContentState;
  entityKey: string;
};

export default function Link({
  contentState,
  entityKey,
  children
}: React.PropsWithChildren<Props>) {
  const data = contentState.getEntity(entityKey).getData() as Immutable.Map<
    string,
    string
  >;
  if ("url" in data) {
    return (
      <a href={data.url as string} style={styleMap["LINK"]}>
        {children}
      </a>
    );
  }
}
