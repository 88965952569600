import { createContext, useContext } from "react";

type Context = {
  state: CaseForm.State;
  dispatch: React.Dispatch<CaseForm.Action>;
  preview?: boolean;
};

export const CaseFormContext = createContext<Context>(null);

export default function useCaseFormContext() {
  return useContext(CaseFormContext);
}
