import { useEffect, useMemo, useState } from "react";
import {
  RawDraftContentState,
  EditorState,
  convertFromRaw,
  convertToRaw
} from "draft-js";

import { csrfToken } from "../lib/request";
import useToastStore from "../stores/useToastStore";
import BottomBar from "./EditRecommendation/BottomBar";
import Form from "./EditRecommendation/Form";
import Tabs from "./EditRecommendation/Tabs";

type EditRecommendationProps = {
  locked: boolean;
  policies: Recommendation.Policies;
  priorities: Priority[];
  recommendation: Recommendation.Recommendation;
  url: string;
  previewUrl: string;
};

function createEditorState(value: RawDraftContentState | null) {
  if (value && Object.keys(value).length > 0) {
    return EditorState.createWithContent(convertFromRaw(value));
  } else {
    return EditorState.createEmpty();
  }
}

function initialState(
  attributes: Recommendation.SerializedAttributes
): Recommendation.Attributes {
  return {
    ...attributes,
    notes: createEditorState(attributes.notes),
    excerpt: createEditorState(attributes.excerpt)
  };
}

function serializeState(
  attributes: Recommendation.Attributes
): Recommendation.SerializedAttributes {
  return {
    ...attributes,
    notes: convertToRaw(attributes.notes.getCurrentContent()),
    excerpt: convertToRaw(attributes.excerpt.getCurrentContent())
  };
}

function hiddenField(name: string, value: string) {
  const field = document.createElement("input");
  field.type = "hidden";
  field.name = name;
  field.value = value;
  return field;
}

export default function EditRecommendation(props: EditRecommendationProps) {
  const [attributes, setAttributes] = useState(
    initialState(props.recommendation.attributes)
  );
  const [id, setId] = useState(props.recommendation.id);
  const [locked, setLocked] = useState(props.locked);
  const [changed, setChanged] = useState(!id);

  const tabs = useMemo(() => {
    const ts = [
      { id: "recommendation", label: "Anbefaling" },
      { id: "proposed", label: "Forslag" },
      { id: "reviews", label: "Faglig vurdering" },
      { id: "previous_decisions", label: "Tidligere vedtak" },
      { id: "excerpt", label: "Søknad" },
      { id: "priority", label: "Prioritet" }
    ];
    if (props.policies.notes) {
      ts.unshift({ id: "activities", label: "Notat" });
      ts.unshift({ id: "documentation", label: "Organisasjon" });
      ts.unshift({ id: "notes", label: "Dokumentasjon" });
    }
    return ts;
  }, [props.policies.notes]);

  const [tab, setTab] = useState(tabs[0]);
  const { notice } = useToastStore((state) => state);

  useEffect(() => {
    if (document && document.location && document.location.hash) {
      const id = document.location.hash.replace(/^#/, "");
      const hashTab = tabs.filter((t) => t.id == id)[0];
      if (hashTab) {
        setTab(hashTab);
      }
    }
  }, [tabs]);

  const updateAttribute = (attr: string) => (value: unknown) => {
    setChanged(true);
    setAttributes({ ...attributes, [attr]: value });
  };

  const save = (evt: React.MouseEvent) => {
    evt.preventDefault();
    const method = id ? "PUT" : "POST";

    const xhr = new XMLHttpRequest();
    xhr.open(method, props.url);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.setRequestHeader("X-CSRF-Token", csrfToken());
    xhr.onload = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        notice("Dine endringer ble lagret");
        const response = JSON.parse(
          xhr.responseText
        ) as Recommendation.Recommendation;
        setChanged(false);
        setId(response.id);
        if (response.attributes.locked) {
          setLocked(response.attributes.locked);
        }
        if (response.url) {
          history.pushState({}, "", `${response.url}#${tab.id}`);
        }
      }
    };
    xhr.send(JSON.stringify({ recommendation: serializeState(attributes) }));
  };

  const preview = (evt: React.MouseEvent) => {
    evt.preventDefault();

    const form = document.createElement("form");
    form.method = "POST";
    form.action = props.previewUrl;
    form.target = "_blank";

    form.appendChild(hiddenField("authenticity_token", csrfToken()));
    form.appendChild(
      hiddenField("recommendation", JSON.stringify(serializeState(attributes)))
    );

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };

  const deleteRecommendation = () => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = props.url;
    form.appendChild(hiddenField("_method", "delete"));
    form.appendChild(hiddenField("authenticity_token", csrfToken()));
    document.body.appendChild(form);
    form.submit();
  };

  return (
    <form className="edit-recommendation" action={props.url} method="post">
      <Tabs tabs={tabs} tab={tab} setTab={setTab} />
      <Form
        locked={locked}
        priorities={props.priorities}
        attributes={attributes}
        reviews={props.recommendation.reviews}
        updateAttribute={updateAttribute}
        deleteRecommendation={
          !locked && props.policies.update && deleteRecommendation
        }
        tab={tab}
      />
      <BottomBar
        kase={props.recommendation.kase}
        changed={changed}
        locked={locked}
        policies={props.policies}
        preview={preview}
        save={save}
      />
    </form>
  );
}
