import { Fragment, useState } from "react";

import DecisionForm from "./DecisionForm";
import Recommendation from "./Recommendation";

type Props = {
  case: MeetingCase.MeetingCase;
  updateCase: (newCase: MeetingCase.MeetingCase) => void;
  updateSelection: (cases: MeetingCase.MeetingCase[]) => void;
  show_recommendations: boolean;
  show_decision_body: boolean;
  edit_decisions: boolean;
  selected: MeetingCase.MeetingCase[];
};

function decisionLabel(d: MeetingCase.Decision) {
  switch (d.status) {
    case "granted":
      return "Tilslag";
    case "rejected":
      return "Avslått";
    default:
      return "Ubehandlet";
  }
}

function formattedText(str: string) {
  return <div className="block" dangerouslySetInnerHTML={{ __html: str }} />;
}

function formatNumber(num?: number | string) {
  if (num) {
    return new Intl.NumberFormat("nb-NO", { style: "decimal" }).format(+num);
  } else {
    return "-";
  }
}

export default function Item(props: Props) {
  const kase = props.case;
  const {
    edit_decisions,
    show_decision_body,
    show_recommendations,
    selected,
    updateCase,
    updateSelection
  } = props;
  const { decision, decision_url, recommendation, reviews } = kase;

  const [expanded, setExpanded] = useState(false);

  const isSelected = () => {
    return selected.indexOf(kase) !== -1;
  };

  const toggleExpanded = (evt: React.MouseEvent) => {
    if (show_recommendations) {
      evt.preventDefault();
      setExpanded(!expanded);
    }
  };

  const openLink = (evt: React.MouseEvent) => {
    evt.stopPropagation();
  };

  const toggleSelected = (evt: React.MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (isSelected()) {
      updateSelection(selected.filter((c) => c !== kase));
    } else {
      updateSelection([kase, ...selected]);
    }
  };

  const shortDecision = () => {
    return (
      <>
        {decision.locked && <i className="material-icons">lock</i>}
        <b>{decisionLabel(decision)}</b>
        <br />
        <span className="light">Tilskuddsramme: </span>
        {formatNumber(decision.sum)}
        <br />
        <span className="light">Egenandel: </span>
        {formatNumber(decision.deductible)}
        {decision.deductible && "%"}
      </>
    );
  };

  const shortRecommendation = () => {
    if (recommendation && show_recommendations) {
      return (
        <>
          <span className="light">Kostnadsramme: </span>
          {formatNumber(recommendation.total_cost)}
          <br />
          <span className="light">Tilskuddsramme: </span>
          {formatNumber(recommendation.grant_sum)}
          <br />
          <span className="light">Egenandel: </span>
          {formatNumber(recommendation.deductible)}
          {recommendation.deductible && "%"}
        </>
      );
    } else {
      return "";
    }
  };

  const renderDecisionBody = () => {
    return (
      <tr className="decision-body">
        <td colSpan={updateSelection ? 2 : 1}></td>
        <td colSpan={4}>
          <h4>Vedtak</h4>
          {formattedText(decision.formatted_body)}
        </td>
      </tr>
    );
  };

  const renderExpanded = () => {
    return (
      <tr>
        <td colSpan={updateSelection ? 2 : 1}></td>
        <td colSpan={4}>
          {edit_decisions && (
            <DecisionForm
              decision={decision}
              updateCase={updateCase}
              url={decision_url}
            />
          )}
          {recommendation && show_recommendations && (
            <Recommendation recommendation={recommendation} reviews={reviews} />
          )}
        </td>
      </tr>
    );
  };

  const classes = [];
  if (show_recommendations) {
    classes.push("expandable");
  }
  if (expanded || show_decision_body) {
    classes.push("expanded");
  }
  return (
    <Fragment>
      <tr onClick={toggleExpanded} className={classes.join(" ")}>
        {updateSelection && (
          <td className="select-toggle">
            <a href="#" onClick={toggleSelected}>
              <i className="material-icons check-box">
                {isSelected() ? "check_box" : "check_box_outline_blank"}
              </i>
            </a>
          </td>
        )}
        <td className="case-number">
          {kase.position}
          <br />
          <a href={kase.url} onClick={openLink}>
            {kase.case_number}
          </a>
          <br />
          {kase.case_processor}
        </td>
        <td>
          <b>{kase.organization_name}</b>
          <br />
          {kase.organization_number}
          <br />
          {[kase.county, kase.municipality].filter((n) => n).join(", ")}
        </td>
        <td>{shortRecommendation()}</td>
        <td className="decision">{shortDecision()}</td>
        <td className="expand-icon"></td>
      </tr>
      {show_decision_body && renderDecisionBody()}
      {expanded && renderExpanded()}
    </Fragment>
  );
}
